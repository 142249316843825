import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';
import get from 'lodash/get';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { ALWAYS_LINKABLE_LINK } from 'dibs-elements/exports/SeoLink';

import styles from './SiteNav-style.scss';

// Current image container has a width to height ratio of 2:1
const CONTAINER_HEIGHT_RATIO_FACTOR = 1;
const CONTAINER_WIDTH_RATIO_FACTOR = 2;

class SiteNavDropdownImageModuleComponent extends Component {
    constructor(props) {
        super(props);
        this.onImageLoad = this.onImageLoad.bind(this);

        this.state = {
            firstImpressionRegistered: false,
            imageIsLoaded: false,
        };
    }

    componentDidUpdate() {
        const { isVisible, navigationModule } = this.props;
        if (
            isVisible &&
            !!get(navigationModule, 'imageUrl') &&
            !this.state.firstImpressionRegistered
        ) {
            this.props.onFirstImpression();
            this.setState({ firstImpressionRegistered: true });
        }
    }

    onImageLoad() {
        this.setState({ imageIsLoaded: true });
    }

    render() {
        const { navigationModule, onClick, isVisible } = this.props;
        const { imageIsLoaded } = this.state;
        const {
            imageUrl,
            imageLinkData = {},
            imageTitlePrimary,
            imageTitleSecondary,
            imageHeight,
            imageWidth,
        } = navigationModule;
        const { path: imageLink } = imageLinkData;

        if (!imageUrl) {
            return null;
        }
        const containerRatio = CONTAINER_WIDTH_RATIO_FACTOR / CONTAINER_HEIGHT_RATIO_FACTOR;
        const imageWidthNum = Number(imageWidth.replace('px', ''));
        const imageHeightNum = Number(imageHeight.replace('px', ''));
        const imageRatio = imageWidthNum / imageHeightNum;

        const imageClasses = classnames(styles.imageModuleImage, {
            // If ratio of image is less than container ratio,
            // need to set image width to 100% (with variable height).
            // This is because any image with a width less than twice
            // it's height - even landscape images - will not fill
            // container if the image height is set to 100% (with variable width)
            // Figured out with the help of:
            // https://medium.com/@elad/how-to-crop-images-with-css-b8471d402b16
            [styles.setWidth]: imageRatio < containerRatio,
            [styles.loadedImage]: imageIsLoaded,
        });

        const imageEl = (
            <div className={styles.imageModuleWrapper}>
                <div className={styles.imageBox}>
                    {(isVisible || imageIsLoaded) && (
                        <img
                            className={imageClasses}
                            src={imageUrl}
                            alt={imageTitlePrimary}
                            onLoad={this.onImageLoad}
                        />
                    )}
                </div>
                <div className={styles.titleBox}>
                    {imageTitleSecondary && (
                        <div className={styles.titleSecondary}>{imageTitleSecondary}</div>
                    )}
                    <div className={styles.titlePrimary}>{imageTitlePrimary}</div>
                </div>
            </div>
        );

        if (imageLink) {
            return (
                <SeoLink
                    className={styles.imageModuleLink}
                    onClick={onClick}
                    variant={ALWAYS_LINKABLE_LINK}
                    linkData={imageLinkData}
                >
                    {imageEl}
                </SeoLink>
            );
        }

        return imageEl;
    }
}

SiteNavDropdownImageModuleComponent.propTypes = {
    navigationModule: PropTypes.object,
    onFirstImpression: PropTypes.func,
    onClick: PropTypes.func,
    isVisible: PropTypes.bool,
};

SiteNavDropdownImageModuleComponent.defaultProps = {
    onFirstImpression: () => {},
    onClick: () => {},
};

export const SiteNavDropdownImageModule = createFragmentContainer(
    SiteNavDropdownImageModuleComponent,
    {
        navigationModule: graphql`
            fragment SiteNavDropdownImageModule_navigationModule on NavigationModule {
                imageLinkData {
                    path
                    ...SeoLink_linkData
                }
                imageUrl
                imageTitlePrimary
                imageTitleSecondary
                imageHeight
                imageWidth
            }
        `,
    }
);
