/**
 * @generated SignedSource<<412a3a77b90281142f3acfee5d39c196>>
 * @relayHash 2a9bd959f31e77ac5f94c991618bc675
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-04T08:13:11.275Z/emailLoginMutation

import { ConcreteRequest } from 'relay-runtime';
export type SupportedMFATypes = "CODE" | "%future added value";
export type UserEmailLoginInput = {
  captchaKey?: string | null;
  captchaToken?: string | null;
  clientMutationId?: string | null;
  cookieDomain: string;
  emailLoginToken: string;
  mfaVerificationCode?: string | null;
  supportedMFATypes?: ReadonlyArray<SupportedMFATypes | null> | null;
};
export type emailLoginMutation$variables = {
  input: UserEmailLoginInput;
};
export type emailLoginMutation$data = {
  readonly userEmailLogin: {
    readonly clientMutationId: string | null;
  } | null;
};
export type emailLoginMutation = {
  response: emailLoginMutation$data;
  variables: emailLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserEmailLoginPayload",
    "kind": "LinkedField",
    "name": "userEmailLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "emailLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "emailLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-04T08:13:11.275Z/emailLoginMutation",
    "metadata": {},
    "name": "emailLoginMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bdec469563498c2fba7c3e354426113c";

export default node;
