import PropTypes from 'prop-types';
import { Component } from 'react';
import classnames from 'classnames';
import styles from './Drawer-style.scss';
import throttle from 'lodash/throttle';

export const drawerAnimations = {
    SLIDE_IN: 'SLIDE_IN', // Sliding into view
    SLIDE_OUT: 'SLIDE_OUT', // Sliding out of view
    NONE: 'NONE',
};

class Drawer extends Component {
    static getWindowScrollY() {
        return window.pageYOffset || document.documentElement.scrollTop;
    }

    constructor(props) {
        super(props);

        this.windowScrollY = null;
        this.animationFrameTicking = false;
        this.animationFrameRequestId = 0;

        this.state = {
            animation: drawerAnimations.NONE,
        };

        this.handleScroll = throttle(this.handleScroll.bind(this), props.scrollThrottleDuration);
    }

    componentDidMount() {
        this.windowScrollY = Drawer.getWindowScrollY();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.forceSlideIn && this.props.forceSlideIn) {
            this.updateScrollState({ animation: drawerAnimations.SLIDE_IN, force: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.cancelAnimationFrame(this.animationFrameRequestId);
    }

    updateScrollState({ animation, force }) {
        const { onStateChange } = this.props;
        const { SLIDE_IN, NONE } = drawerAnimations;

        if (this.state.animation === NONE && animation === SLIDE_IN) {
            return;
        }
        if (this.state.animation !== animation || force) {
            this.setState({ animation });
            if (onStateChange) {
                onStateChange(animation === SLIDE_IN);
            }
        }
    }

    handleScroll() {
        const currScrollY = Drawer.getWindowScrollY();
        const prevScrollY = this.windowScrollY;
        // aligned with packages/dibs-buyer-layout/src/components/Header/Header.jsx
        const MESSAGE_BANNER_HEIGHT = 42;

        if (
            (typeof prevScrollY !== 'number' && !Number.isNan(prevScrollY)) ||
            prevScrollY < 0 ||
            currScrollY < 0 ||
            (currScrollY < MESSAGE_BANNER_HEIGHT && prevScrollY < MESSAGE_BANNER_HEIGHT)
        ) {
            return;
        }

        if (this.props.__disableAnimationFrameTicking || !this.animationFrameTicking) {
            // Since scroll events can fire at a high rate, the event handler shouldn't execute
            // computationally expensive operations--it is recommended to throttle the event
            // https://developer.mozilla.org/en-US/docs/Web/Events/scroll
            this.animationFrameRequestId = window.requestAnimationFrame(() => {
                const animation =
                    currScrollY > prevScrollY
                        ? drawerAnimations.SLIDE_OUT
                        : drawerAnimations.SLIDE_IN;

                this.updateScrollState({ animation });

                this.animationFrameTicking = false;
                this.windowScrollY = currScrollY;
            });
        }
        this.animationFrameTicking = true;
    }

    render() {
        const { children } = this.props;
        const { animation } = this.state;

        const classes = classnames({
            [styles.drawer]: true,
            [styles.slideOut]: animation === drawerAnimations.SLIDE_OUT,
            [styles.slideIn]: animation === drawerAnimations.SLIDE_IN,
        });

        return <div className={classes}>{children}</div>;
    }
}

Drawer.defaultProps = {
    scrollThrottleDuration: 200,
};

Drawer.propTypes = {
    __disableAnimationFrameTicking: PropTypes.bool,
    scrollThrottleDuration: PropTypes.number,
    forceSlideIn: PropTypes.bool,
    onScroll: PropTypes.func,
    animation: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onStateChange: PropTypes.func,
    hideBottomBar: PropTypes.bool,
};

export default Drawer;
