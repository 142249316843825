import { Component, Fragment } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'dibs-react-intl';
import { Switch } from 'dibs-elements/exports/Switch';
import style from './ShareActivityAlert-style.scss';
import { editUserPreferencesMutation } from '../../mutations/editUserPreferencesMutation';
import { trackEvent } from 'dibs-tracking';
import { localStorage } from 'dibs-browser-storage';
import get from 'lodash/get';
import { shareActivitiesDisplay, shareActivitiesUpdate } from './trackingHelpers';

const SHARE_ACTIVITY_HOVER_COUNT_STORAGE_KEY = 'SHARE_ACTIVITY_HOVER_COUNT_STORAGE_KEY';

export class ShareActivityAlertBase extends Component {
    constructor(props) {
        super(props);
        const user = this.props.user;
        this.state = {
            showSaved: false,
            shareActivities: get(user, 'preferences.shareActivities'),
        };
    }

    componentDidMount() {
        if (!this.props.autoDisplayed) {
            const timesHovered = localStorage.getItem(SHARE_ACTIVITY_HOVER_COUNT_STORAGE_KEY) || 0;
            const trigger = 'hover-state';
            const timesDisplayed = timesHovered + 1;
            trackEvent(shareActivitiesDisplay({ trigger, timesDisplayed }));
            localStorage.setItem(SHARE_ACTIVITY_HOVER_COUNT_STORAGE_KEY, timesHovered + 1);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && !prevProps.user) {
            this.setState({
                shareActivities: this.props.user.preferences.shareActivities,
            });
        }
    }

    onChange({ value }) {
        this.setState({
            shareActivities: value,
        });

        editUserPreferencesMutation(this.props.relay.environment, {
            userId: this.props.user.serviceId,
            shareActivities: value,
        })
            .then(() => {
                this.setState({ showSaved: true });
                setTimeout(() => {
                    this.setState({ showSaved: false });
                }, 2500);
                const trackingValue = this.state.shareActivities ? 'enabled' : 'disabled';
                trackEvent(shareActivitiesUpdate(trackingValue));
            })
            .catch(response => {
                this.setState({ showSaved: false });
                // eslint-disable-next-line no-console
                console.error("Profile wasn't saved: ", response);
            });
    }

    render() {
        return (
            <Fragment>
                <div className={style.container}>
                    <div className={style.heading}>
                        <div className={style.goldHeading}>
                            <FormattedMessage
                                id="dbl.Header.activityNotification.new"
                                defaultMessage="NEW"
                            />
                        </div>
                        <FormattedMessage
                            id="dbl.Header.activityNotification.intro"
                            defaultMessage="Personalize Your Experience"
                        />
                    </div>
                    <div className={style.bodyText}>
                        <FormattedMessage
                            id="dbl.Header.activityNotification.body"
                            defaultMessage="Share items you’re interested in so sellers can offer personalized recommendations or items not publicly available."
                        />
                    </div>
                    <div className={style.promptBody}>
                        <div className={style.promptText}>
                            <FormattedMessage
                                id="dbl.Header.activityNotification.switch"
                                defaultMessage="Share interests with sellers I contact."
                            />
                        </div>
                        <div className={style.switch}>
                            <Switch
                                checked={this.state.shareActivities}
                                dataTn={`dbl-activityNotification-switch`}
                                onChange={value => {
                                    this.onChange({ value: value });
                                }}
                            />
                        </div>
                    </div>
                    <div className={style.savingMessage}>
                        {this.state.showSaved && (
                            <FormattedMessage
                                id="dbl.Header.activityNotification.saved"
                                defaultMessage="Setting saved..."
                            />
                        )}
                    </div>
                    <div className={style.subText}>
                        <FormattedMessage
                            id="dbl.Header.activityNotification.subText"
                            defaultMessage="Update anytime in your Profile Preferences."
                        />
                    </div>
                </div>
                <div className={style.sectionDivider} />
            </Fragment>
        );
    }
}

ShareActivityAlertBase.propTypes = {
    autoDisplayed: PropTypes.bool,
    user: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    errorHandler: PropTypes.func,
};

const ShareActivityAlert = createFragmentContainer(ShareActivityAlertBase, {
    user: graphql`
        fragment ShareActivityAlert_user on User {
            serviceId
            preferences {
                shareActivities
            }
        }
    `,
});

export default ShareActivityAlert;
