import get from 'lodash/get';
import { localStorage } from 'dibs-browser-storage';
import { RECENT_SEARCHES } from './sharedSearchBarConstants';
import { defineMessages } from 'dibs-react-intl';

const titles = defineMessages({
    RECENT: {
        id: 'search.suggestions.recent.type',
        defaultMessage: 'Recent searches',
    },
    POPULAR: {
        id: 'search.suggestions.popular.type',
        defaultMessage: 'Popular searches',
    },
    CREATOR: {
        id: 'search.suggestions.creator.type',
        defaultMessage: 'Creators',
    },
    STYLE: {
        id: 'search.suggestions.style.type',
        defaultMessage: 'Styles',
    },
    SELLER: {
        id: 'search.suggestions.seller.type',
        defaultMessage: 'Sellers',
    },
    CATEGORY: {
        id: 'search.suggestions.category.type',
        defaultMessage: 'Categories',
    },
});

const sectionsByType = intl => ({
    RECENT: {
        title: intl.formatMessage(titles.RECENT),
        urlSection: null,
    },

    POPULAR: {
        title: intl.formatMessage(titles.POPULAR),
        urlSection: null,
    },
    CREATOR: {
        title: intl.formatMessage(titles.CREATOR),
        urlSection: 'creators',
    },
    STYLE: {
        title: intl.formatMessage(titles.STYLE),
        urlSection: 'styles',
    },
    SELLER: {
        title: intl.formatMessage(titles.SELLER),
        urlSection: 'dealers',
    },
    CATEGORY: {
        title: intl.formatMessage(titles.CATEGORY),
        urlSection: null,
    },
});

const getSectionPropsByType = (type, intl) => get(sectionsByType(intl), type) || {};

// analytics needs full `term` value for dealer storefront click through
const createSearchLink = ({ term, urlSection, urlLabel, type }) => {
    const convertedValue = encodeURIComponent(term.toLowerCase());
    let href;

    if (urlSection && urlLabel) {
        href = `/${urlSection}/${urlLabel}/?search=${convertedValue}`;
    } else if (!urlSection && urlLabel && type !== RECENT_SEARCHES.TYPE) {
        // urlLabel is stored as full link in RECENT object
        // Only use urlLabel to build link if not from RECENT
        href = `${urlLabel}?search=${convertedValue}`;
    } else {
        href = `/search/?q=${convertedValue}`;
    }

    return href;
};

const dedupeLocalStorage = () => {
    const storageData = localStorage.getItem(RECENT_SEARCHES.LOCAL_STORAGE) || [];
    const dedupeData = [];

    storageData.forEach((section, index) => {
        const terms = {};
        dedupeData.push({ type: section.type, terms: [] });
        section.terms.forEach(termObj => {
            if (!terms[termObj.term]) {
                terms[termObj.term] = true;
                dedupeData[index].terms.push(termObj);
            }
        });
    });
    return dedupeData.length ? dedupeData : undefined;
};

const getCursorTerms = (data, currentValue, intl) => {
    let terms = data.map(suggestion =>
        suggestion.terms.map((item, index) => {
            const { type } = suggestion;
            const { term, urlLabel } = item;
            const { urlSection } = getSectionPropsByType(suggestion.type, intl);
            return {
                term,
                highlightId: `${term}-${type}-${index}`,
                link: createSearchLink({ term, urlSection, urlLabel, type }),
            };
        })
    );
    terms = [].concat(...terms);
    terms.push({ term: currentValue, highlightId: `${currentValue}-USER_INPUT` });
    return terms;
};

const handleRecentStorage = (term, urlLabel) => {
    const search = { term, urlLabel };
    let localRecentSearches = localStorage.getItem(RECENT_SEARCHES.LOCAL_STORAGE) || [];
    let recentTerms = [];

    if (!localRecentSearches.length) {
        localRecentSearches = [
            {
                type: RECENT_SEARCHES.TYPE,
                terms: [search],
            },
        ];
    } else {
        recentTerms = localRecentSearches[0].terms;
        if (recentTerms.length === RECENT_SEARCHES.MAX_TERMS) {
            recentTerms.pop();
        }
        recentTerms.unshift(search);
    }

    localStorage.setItem(RECENT_SEARCHES.LOCAL_STORAGE, JSON.stringify(localRecentSearches));
};

const cleanUpRecentSearches = () => {
    const storageData = localStorage.getItem(RECENT_SEARCHES.LOCAL_STORAGE) || [];
    const cleanedRecentSearches = storageData.reduce((cleanedStorageData, section) => {
        if (section.type && Array.isArray(section.terms)) {
            section.terms = section.terms.filter(termObj => termObj.term && termObj.urlLabel);
            return [...cleanedStorageData, section];
        }
        return cleanedStorageData;
    }, []);
    localStorage.setItem(RECENT_SEARCHES.LOCAL_STORAGE, JSON.stringify(cleanedRecentSearches));
};

export {
    getSectionPropsByType,
    createSearchLink,
    dedupeLocalStorage,
    getCursorTerms,
    handleRecentStorage,
    cleanUpRecentSearches,
};
