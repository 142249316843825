/**
 * @generated SignedSource<<ce0c5111324c841460fa601afdd02380>>
 * @relayHash 26899c0f33cebf99558583b77baa6aff
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-04T08:13:11.275Z/HeaderRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderRefetchQuery$variables = {
  fetchRegionalInfo: boolean;
  fetchTrackingGdprInfo?: boolean | null;
  fetchUserRecentHistoryItem?: boolean | null;
  hasTradeUserId: boolean;
  hasUserId: boolean;
  isClient: boolean;
  isGeoLocationPromoGroupSet: boolean;
  localRecentHistoryItemIds?: ReadonlyArray<string | null> | null;
  previewKey?: string | null;
  priceBookName: string;
  userCountryCode?: string | null;
  userId: string;
  userIds: ReadonlyArray<string | null>;
  variantId?: string | null;
  zipCode: string;
};
export type HeaderRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Header_viewer">;
  };
};
export type HeaderRefetchQuery = {
  response: HeaderRefetchQuery$data;
  variables: HeaderRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchRegionalInfo"
},
v1 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "fetchTrackingGdprInfo"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchUserRecentHistoryItem"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTradeUserId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isGeoLocationPromoGroupSet"
},
v7 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "localRecentHistoryItemIds"
},
v8 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "previewKey"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v10 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v13 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "variantId"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zipCode"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "path",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isLinkable",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v20 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v21 = [
  (v20/*: any*/)
],
v22 = {
  "kind": "Variable",
  "name": "buyerId",
  "variableName": "userId"
},
v23 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVip",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerifiedTrade",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v27 = [
  (v26/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = [
  {
    "kind": "Variable",
    "name": "excludeItemPks",
    "variableName": "localRecentHistoryItemIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  }
],
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v31 = [
  (v19/*: any*/),
  (v15/*: any*/)
],
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileStatus",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmRewards",
  "kind": "LinkedField",
  "name": "rewards",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ParentProgram",
      "kind": "LinkedField",
      "name": "currentProgram",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LoyaltyProgram",
          "kind": "LinkedField",
          "name": "programInfo",
          "plural": false,
          "selections": [
            (v35/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyerDisplayable",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "Summary",
  "kind": "LinkedField",
  "name": "conversationSummary",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unread",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadActiveOffers",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v40 = [
  (v15/*: any*/)
],
v41 = {
  "kind": "InlineFragment",
  "selections": (v40/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v42 = [
  (v26/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v43 = [
  {
    "kind": "Literal",
    "name": "elementTypes",
    "value": [
      "PENDING_FEEDBACK",
      "TRADE_REWARD",
      "TRADE_REWARD_PRORATED",
      "TRADE_REWARD_PROGRAM",
      "AUTOMATED_OFFER"
    ]
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
],
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventDate",
  "storageKey": null
},
v46 = {
  "alias": "thumbnail",
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": "thumb"
    }
  ],
  "kind": "ScalarField",
  "name": "firstPhotoWebPath",
  "storageKey": "firstPhotoWebPath(size:\"thumb\")"
},
v47 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": (v42/*: any*/),
    "storageKey": null
  }
],
v48 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "zipCode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v11/*: any*/),
      (v12/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v10/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "HeaderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isMultiItemCartFF",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "multiItemCart"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"multiItemCart\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CartTotals",
            "kind": "LinkedField",
            "name": "userCartTotals",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "savedItemsTotal",
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "isPopularSearchesEnabledByLocale",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "POPULAR_SEARCHES"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"POPULAR_SEARCHES\")"
          },
          {
            "alias": "activeOfferNavFF",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "activeOfferNav"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"activeOfferNav\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currencies",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geography",
            "kind": "LinkedField",
            "name": "geography",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeographyObject",
                "kind": "LinkedField",
                "name": "countries",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abbreviation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "scandinavianCurrenciesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "scandinavianCurrencies"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"scandinavianCurrencies\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "rootId",
                "variableName": "previewKey"
              },
              {
                "kind": "Variable",
                "name": "variantId",
                "variableName": "variantId"
              }
            ],
            "concreteType": "Navigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationModule",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NavigationTopLevel",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "column",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationItem",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v16/*: any*/),
                          (v15/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LinkData",
                    "kind": "LinkedField",
                    "name": "imageLinkData",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageTitlePrimary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageTitleSecondary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageHeight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageWidth",
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "relayIsClient",
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "if": null,
                "kind": "Defer",
                "label": "Notifications_initialViewer$defer$NotificationsIcon_viewer",
                "selections": [
                  {
                    "condition": "hasUserId",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v21/*: any*/),
                        "concreteType": "ConversationSummary",
                        "kind": "LinkedField",
                        "name": "conversationsSummary",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastUnreadMessageDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "if": null,
                "kind": "Defer",
                "label": "Header_viewer$defer$userTracking_viewer",
                "selections": [
                  {
                    "condition": "hasUserId",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          (v22/*: any*/)
                        ],
                        "concreteType": "TransactionsCounter",
                        "kind": "LinkedField",
                        "name": "transactionsCounter",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "submittedTransactions",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submitted",
                            "storageKey": null
                          },
                          {
                            "alias": "confirmedTransactions",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "confirmed",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "fetchTrackingGdprInfo",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "gdprDisplayInfo",
                        "kind": "LinkedField",
                        "name": "gdprDisplayInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              (v23/*: any*/)
                            ],
                            "kind": "ScalarField",
                            "name": "isGdprApplicable",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "CART"
                    ]
                  },
                  {
                    "kind": "Variable",
                    "name": "userIds",
                    "variableName": "userIds"
                  }
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 0
                              }
                            ],
                            "concreteType": "PortfolioItemsConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalResults",
                                "storageKey": null
                              }
                            ],
                            "storageKey": "items(first:0)"
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v28/*: any*/),
                  {
                    "if": null,
                    "kind": "Defer",
                    "label": "SearchBar_viewer$defer$getRecentHistory_user",
                    "selections": [
                      {
                        "condition": "fetchUserRecentHistoryItem",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v29/*: any*/),
                            "concreteType": "RecentHistoryItemsConnection",
                            "kind": "LinkedField",
                            "name": "recentHistoryItems",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecentHistoryItemsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "contemporaryTrackingString",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "size",
                                            "value": "small"
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "firstPhotoWebPath",
                                        "storageKey": "firstPhotoWebPath(size:\"small\")"
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "pdpURL",
                                        "storageKey": null
                                      },
                                      (v30/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "browseUrl",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryCode",
                                        "storageKey": null
                                      },
                                      (v19/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "categoryPath",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Seller",
                                        "kind": "LinkedField",
                                        "name": "seller",
                                        "plural": false,
                                        "selections": (v31/*: any*/),
                                        "storageKey": null
                                      },
                                      (v15/*: any*/),
                                      (v32/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v29/*: any*/),
                            "filters": [
                              "excludeItemPks"
                            ],
                            "handle": "connection",
                            "key": "getRecentHistory_recentHistoryItems",
                            "kind": "LinkedHandle",
                            "name": "recentHistoryItems"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "if": null,
                    "kind": "Defer",
                    "label": "Header_viewer$defer$userTracking_user",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdDate",
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "verifiedTradeStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserExistsMetadataType",
                        "kind": "LinkedField",
                        "name": "authMetadata",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emailOnly",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasPassword",
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          (v33/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "zipCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emailMd5",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emailSha256",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v28/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalMessagesToDealers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmType",
                        "kind": "LinkedField",
                        "name": "tradeFirm",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v34/*: any*/),
                          (v36/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Promotion",
                        "kind": "LinkedField",
                        "name": "activePromotion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "campaignName",
                            "storageKey": null
                          },
                          (v37/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "isClient",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": (v27/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddressBook",
                        "kind": "LinkedField",
                        "name": "addressBook",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Address",
                            "kind": "LinkedField",
                            "name": "personalAddress",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayCountry",
                                "storageKey": null
                              },
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "if": null,
                        "kind": "Defer",
                        "label": "AccountNav_user$defer$AccountNavContent_user",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Profile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              (v33/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v38/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EthereumAddressType",
                            "kind": "LinkedField",
                            "name": "ethereumWalletAddress",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "address",
                                "storageKey": null
                              },
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "if": null,
                        "kind": "Defer",
                        "label": "AccountNav_user$defer$AccountNavTrigger_user",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Profile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v25/*: any*/),
                          {
                            "condition": "hasTradeUserId",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TradeFirmRewards",
                                "kind": "LinkedField",
                                "name": "tradeFirmRewards",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "currentYearPoints",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ParentProgram",
                                    "kind": "LinkedField",
                                    "name": "currentProgram",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LoyaltyProgram",
                                        "kind": "LinkedField",
                                        "name": "programInfo",
                                        "plural": false,
                                        "selections": [
                                          (v39/*: any*/),
                                          (v15/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "hasTradeUserId",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "if": null,
                            "kind": "Defer",
                            "label": "AccountNav_user$defer$TradeAccountNavContent_user",
                            "selections": [
                              (v19/*: any*/),
                              (v25/*: any*/),
                              (v38/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TradeFirmType",
                                "kind": "LinkedField",
                                "name": "tradeFirm",
                                "plural": false,
                                "selections": [
                                  (v19/*: any*/),
                                  (v34/*: any*/),
                                  (v36/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TradeFirmPublicProfileType",
                                    "kind": "LinkedField",
                                    "name": "publicProfile",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "urlLabel",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TradeFirmMemberType",
                                    "kind": "LinkedField",
                                    "name": "members",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "userId",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "publicProfileManager",
                                        "storageKey": null
                                      },
                                      (v15/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "isClient",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "if": null,
                            "kind": "Defer",
                            "label": "Notifications_user$defer$ShareActivityAlert_user",
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserPreferences",
                                "kind": "LinkedField",
                                "name": "preferences",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "shareActivities",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "if": null,
                            "kind": "Defer",
                            "label": "Notifications_user$defer$ActivityFeed_user",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Promotion",
                                "kind": "LinkedField",
                                "name": "activePromotion",
                                "plural": false,
                                "selections": [
                                  (v37/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PromotionMeta",
                                    "kind": "LinkedField",
                                    "name": "promotionMeta",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "displayMessage",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "displayCta",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "adjustmentRateList",
                                    "plural": true,
                                    "selections": [
                                      (v32/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "reference",
                                        "storageKey": null
                                      },
                                      (v41/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v35/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlatAdjustmentRate",
                                    "kind": "LinkedField",
                                    "name": "flatAdjustmentRate",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "FinancialAmount",
                                        "kind": "LinkedField",
                                        "name": "rate",
                                        "plural": false,
                                        "selections": (v42/*: any*/),
                                        "storageKey": null
                                      },
                                      (v15/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PercentAdjustmentRate",
                                    "kind": "LinkedField",
                                    "name": "percentAdjustmentRate",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "rate",
                                        "storageKey": null
                                      },
                                      (v15/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v43/*: any*/),
                                "concreteType": "ActivityFeedConnection",
                                "kind": "LinkedField",
                                "name": "activityFeed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ActivityFeedEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ActivityFeedElement",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v44/*: any*/),
                                          (v45/*: any*/),
                                          (v19/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "apiType",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Anchor",
                                            "kind": "LinkedField",
                                            "name": "anchors",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "sourceType",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "source",
                                                "plural": false,
                                                "selections": [
                                                  (v32/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      (v19/*: any*/),
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "FinancialAmount",
                                                        "kind": "LinkedField",
                                                        "name": "rewardEarned",
                                                        "plural": false,
                                                        "selections": (v42/*: any*/),
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "TradeFirmReward",
                                                    "abstractKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "LoyaltyProgram",
                                                        "kind": "LinkedField",
                                                        "name": "programInfo",
                                                        "plural": false,
                                                        "selections": [
                                                          (v39/*: any*/),
                                                          (v35/*: any*/),
                                                          (v15/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "ParentProgram",
                                                    "abstractKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "token",
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Transaction",
                                                        "kind": "LinkedField",
                                                        "name": "transaction",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Item",
                                                            "kind": "LinkedField",
                                                            "name": "item",
                                                            "plural": false,
                                                            "selections": [
                                                              (v19/*: any*/),
                                                              (v30/*: any*/),
                                                              (v46/*: any*/),
                                                              (v15/*: any*/)
                                                            ],
                                                            "storageKey": null
                                                          },
                                                          (v15/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Item",
                                                        "kind": "LinkedField",
                                                        "name": "item",
                                                        "plural": false,
                                                        "selections": (v31/*: any*/),
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "OrderFeedback",
                                                    "abstractKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      (v19/*: any*/),
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "TransactionSummaryObject",
                                                        "kind": "LinkedField",
                                                        "name": "summary",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "offerDiscountPercentage",
                                                            "storageKey": null
                                                          }
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Item",
                                                        "kind": "LinkedField",
                                                        "name": "item",
                                                        "plural": false,
                                                        "selections": [
                                                          (v19/*: any*/),
                                                          (v30/*: any*/),
                                                          (v46/*: any*/),
                                                          {
                                                            "alias": null,
                                                            "args": [
                                                              (v22/*: any*/),
                                                              {
                                                                "kind": "Variable",
                                                                "name": "isTrade",
                                                                "variableName": "hasTradeUserId"
                                                              },
                                                              {
                                                                "kind": "Variable",
                                                                "name": "priceBookName",
                                                                "variableName": "priceBookName"
                                                              },
                                                              {
                                                                "kind": "Variable",
                                                                "name": "userCountryCode",
                                                                "variableName": "userCountryCode"
                                                              }
                                                            ],
                                                            "concreteType": "ItemDisplayPriceType",
                                                            "kind": "LinkedField",
                                                            "name": "displayPrice",
                                                            "plural": true,
                                                            "selections": (v47/*: any*/),
                                                            "storageKey": null
                                                          },
                                                          (v15/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Offer",
                                                        "kind": "LinkedField",
                                                        "name": "mostRecentOffer",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FinancialAmount",
                                                            "kind": "LinkedField",
                                                            "name": "amountPerItem",
                                                            "plural": false,
                                                            "selections": (v47/*: any*/),
                                                            "storageKey": null
                                                          },
                                                          (v15/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Conversation",
                                                        "kind": "LinkedField",
                                                        "name": "conversation",
                                                        "plural": false,
                                                        "selections": (v31/*: any*/),
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "Transaction",
                                                    "abstractKey": null
                                                  },
                                                  (v41/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": (v40/*: any*/),
                                                    "type": "Category",
                                                    "abstractKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v15/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "activityFeed(elementTypes:[\"PENDING_FEEDBACK\",\"TRADE_REWARD\",\"TRADE_REWARD_PRORATED\",\"TRADE_REWARD_PROGRAM\",\"AUTOMATED_OFFER\"],first:3)"
                              }
                            ]
                          },
                          {
                            "if": null,
                            "kind": "Defer",
                            "label": "Notifications_user$defer$NotificationsIcon_user",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Promotion",
                                "kind": "LinkedField",
                                "name": "activePromotion",
                                "plural": false,
                                "selections": (v31/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v43/*: any*/),
                                "concreteType": "ActivityFeedConnection",
                                "kind": "LinkedField",
                                "name": "activityFeed",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ActivityFeedEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ActivityFeedElement",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v44/*: any*/),
                                          (v45/*: any*/),
                                          (v15/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "activityFeed(elementTypes:[\"PENDING_FEEDBACK\",\"TRADE_REWARD\",\"TRADE_REWARD_PRORATED\",\"TRADE_REWARD_PROGRAM\",\"AUTOMATED_OFFER\"],first:3)"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isGeoLocationPromoGroupSet",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "listTypes",
                    "value": [
                      "ABTEST_PROMOTIONS"
                    ]
                  },
                  (v20/*: any*/)
                ],
                "concreteType": "PromotionVariantControl",
                "kind": "LinkedField",
                "name": "promotionVariantControl",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promoGroup",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promotionCode",
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v23/*: any*/),
                  (v20/*: any*/),
                  (v48/*: any*/)
                ],
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v48/*: any*/)
                    ],
                    "concreteType": "IncomeLevel",
                    "kind": "LinkedField",
                    "name": "incomeLevel",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "incomeBracket",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "incomePercentile",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v26/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-04T08:13:11.275Z/HeaderRefetchQuery",
    "metadata": {},
    "name": "HeaderRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "30439567275f628e4bebf10a59dffa71";

export default node;
