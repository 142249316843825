import { createRef, Fragment, Suspense, Component } from 'react';
import { bool, string, object } from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';

import { localStorage } from 'dibs-browser-storage';
import {
    trackGeoLocationPromos,
    getGeoLocationPromoGroupFromLocalStorage,
} from 'dibs-display-promo-code/exports/promoHelpers';

import Dropdown, { alignConstants } from '../Dropdown/Dropdown';
import DropdownBody from '../Dropdown/DropdownBody';
import DropdownLoadingContent from '../Dropdown/DropdownLoadingContent';
import NotificationsIcon from './NotificationsIcon';
import ActivityFeedLazy from './ActivityFeedLazy';
import ConversationsLazy from './ConversationsLazy';
import { setPromoCodeNotificationShown } from '../../utils/promoCode.es';
import { getNotificationName, trackNotification } from './notificationTracking';

//imports for shareActivityAlert
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_NOTIFICATIONS_CLICK } = stepInteractionConstants;
import ShareActivityAlert from './ShareActivityAlert';
import { shouldDisableNotifications } from '../../utils/notificationsHelper';

import styles from './Notifications-style.scss';

const BATCH_SIZE = 5;
const AUTOMATED_OFFER_VIEW_COUNT_DROPDOWN_TRIGGER_TRESHOLD = 2;
const AUTOMATED_OFFER_COUNT_STORAGE_KEY = 'AUTOMATED_OFFER_COUNT_STORAGE_KEY';
export const LOCAL_STORAGE_KEY_NAME = 'NAV_NOTIFICATIONS_DROPDOWN_LAST_OPEN_TIME';

const resetState = () => ({
    isOpen: false,
    fetchingNotifications: true,
    first: BATCH_SIZE,
    isDropdownOpen: false,
});
const stopFetching = (isMore = false) => {
    const defaultReturn = { fetchingNotifications: false };
    return isMore ? Object.assign({}, defaultReturn) : defaultReturn;
};
const startFetching = () => ({ fetchingNotifications: true });
const openDropdown = () => ({ isOpen: true });

const isNewNotification = eventDate =>
    new Date(eventDate).getTime() > Number(localStorage.getItem(LOCAL_STORAGE_KEY_NAME)) || 0;
const trackNotificationAlerts = notifications => {
    notifications.forEach(notification => {
        const { eventDate, type } = notification;
        if (type === 'TRADE_REWARD') {
            return;
        }

        if (isNewNotification(eventDate)) {
            trackNotification({ label: getNotificationName(type), action: 'alert displayed' });
        }
    });
};

export class NotificationsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...resetState(),
            showShareActivity: false,
            triggerDropdown: false,
            activePromotion: null,
        };

        this.dropdownBodyRef = createRef();
        this.lastReadTime = 0;

        this.loadNotifications = this.loadNotifications.bind(this);
        this.onMouseEnterIcon = this.onMouseEnterIcon.bind(this);
        this.handleDropdownBlur = this.handleDropdownBlur.bind(this);
        this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
        this.onActivePromotionFetched = this.onActivePromotionFetched.bind(this);
        this.onActivityFeedFetched = this.onActivityFeedFetched.bind(this);
    }

    componentDidMount() {
        this.lastReadTime = Number(localStorage.getItem(LOCAL_STORAGE_KEY_NAME)) || 0;
    }

    showAutomatedOfferAlert(activityFeed) {
        const { isVipCuratedFolderTooltipVisible } = this.props || {};

        if (shouldDisableNotifications() || isVipCuratedFolderTooltipVisible) {
            return;
        }

        const savedAutomatedOfferViewCounts =
            localStorage.getItem(AUTOMATED_OFFER_COUNT_STORAGE_KEY) || {};

        const automatedOfferViewCounts = activityFeed.reduce((viewCounts, currentNotification) => {
            if (currentNotification.node.type === 'AUTOMATED_OFFER') {
                const notificationId = currentNotification.node.serviceId;
                return {
                    ...viewCounts,
                    [notificationId]: (savedAutomatedOfferViewCounts[notificationId] || 0) + 1,
                };
            }
            return viewCounts;
        }, {});

        const smallestViewCount = Math.min(...Object.values(automatedOfferViewCounts));
        const triggerDropdown =
            smallestViewCount <= AUTOMATED_OFFER_VIEW_COUNT_DROPDOWN_TRIGGER_TRESHOLD;

        this.setState({ triggerDropdown }, () => {
            if (triggerDropdown) {
                this.loadNotifications();
                localStorage.setItem(AUTOMATED_OFFER_COUNT_STORAGE_KEY, automatedOfferViewCounts);
            }
        });
    }

    onActivityFeedFetched(activityFeed) {
        const feed = activityFeed?.edges;
        if (Array.isArray(feed)) {
            trackNotificationAlerts(feed.map(edge => edge.node));
            this.showAutomatedOfferAlert(feed);
        }
    }

    onActivePromotionFetched(activePromotion) {
        this.setState({ activePromotion });
    }

    loadNotifications() {
        const { hasRootPendingRefetch, relay, userId } = this.props;
        const fetchNotificationVariables = {
            fetchNotifications: true,
            userId,
            hasUserId: !!userId,
            buyerId: userId,
            isGeoLocationPromoGroupSet: !!getGeoLocationPromoGroupFromLocalStorage().length,
        };

        if (hasRootPendingRefetch) {
            return;
        }

        const refetchVariables = fragmentVariables =>
            Object.assign({}, fragmentVariables, fetchNotificationVariables);
        const refetchCallback = () => {
            localStorage.setItem(LOCAL_STORAGE_KEY_NAME, Date.now());
            if (this.state.activePromotion) {
                setPromoCodeNotificationShown(this.state.activePromotion);
            }
            this.setState(stopFetching);
        };

        this.setState(startFetching, () =>
            relay.refetch(refetchVariables, null, refetchCallback, { force: true })
        );
    }

    onMouseEnterIcon(e) {
        e.stopPropagation();
        if (!this.state.isOpen) {
            this.loadNotifications();
            this.setState(openDropdown);
            const label = 'notification icon hover or click';
            trackEvent({
                label,
                category: 'navigation',
                action: `header notifications`,
                isInteractiveEvent: false,
                value: 0,
                eventName: EVENT_NAVIGATION,
                interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_NOTIFICATIONS_CLICK,
                trigger: label,
            });
            trackGeoLocationPromos({ viewer: this.props.viewer });
        }
    }

    handleDropdownToggle() {
        if (!this.state.isOpen) {
            this.loadNotifications();
            this.setState({ isOpen: true, triggerDropdown: true });
        } else {
            this.setState({ isOpen: false, triggerDropdown: false });
        }
    }
    handleDropdownBlur(e) {
        if (
            this.dropdownBodyRef.current &&
            !this.dropdownBodyRef.current.contains(e.relatedTarget)
        ) {
            this.setState({ isOpen: false, triggerDropdown: false });
        }
    }

    render() {
        const {
            hasUserId,
            isClient,
            user,
            viewer,
            initialViewer,
            isVipCuratedFolderTooltipVisible,
        } = this.props;
        const { fetchingNotifications, showShareActivity, triggerDropdown } = this.state;

        if (!isClient || !hasUserId || !user) {
            return null;
        }

        const conversations = get(this.props, 'viewer.conversations') || null;

        return (
            <Dropdown
                hasDropdownHeader
                align={alignConstants.ALIGN_RIGHT}
                forceOpen={triggerDropdown}
                onHide={() => {
                    this.setState(resetState);
                }}
                onShow={() => {
                    this.setState({ isDropdownOpen: true });
                }}
                className={classnames({
                    [styles.noHover]: isVipCuratedFolderTooltipVisible,
                })}
                bodyClassName={styles.dropdownBodyWrapper}
                dropdownClassName={styles.dropdown}
            >
                <Suspense fallback={null}>
                    <NotificationsIcon
                        viewer={initialViewer}
                        user={user}
                        isOpen={this.state.isOpen}
                        onActivePromotionFetched={this.onActivePromotionFetched}
                        onMouseEnter={this.onMouseEnterIcon}
                        onClick={this.handleDropdownToggle}
                    />
                </Suspense>
                {this.state.isDropdownOpen && (
                    <DropdownBody ref={this.dropdownBodyRef} onBlur={this.handleDropdownBlur}>
                        <Fragment>
                            {showShareActivity && (
                                <ShareActivityAlert
                                    user={user}
                                    viewer={viewer}
                                    autoDisplayed={triggerDropdown}
                                />
                            )}
                            <Suspense fallback={<DropdownLoadingContent />}>
                                <ActivityFeedLazy
                                    user={user}
                                    lastReadTime={this.lastReadTime}
                                    onActivityFeedFetched={this.onActivityFeedFetched}
                                />
                                <ConversationsLazy
                                    conversations={conversations}
                                    isLoading={fetchingNotifications}
                                />
                            </Suspense>
                        </Fragment>
                    </DropdownBody>
                )}
            </Dropdown>
        );
    }
}

NotificationsComponent.propTypes = {
    hasUserId: bool,
    hasRootPendingRefetch: bool,
    isClient: bool,
    relay: object,
    userId: string,
    initialViewer: object,
    viewer: object,
    user: object,
    isVipCuratedFolderTooltipVisible: bool,
};

const mapStateToProps = ({ header }) => {
    const isVipCuratedFolderTooltipVisible = header?.isVipCuratedFolderTooltipVisible;
    return {
        isVipCuratedFolderTooltipVisible,
    };
};

const Notifications = createRefetchContainer(
    connect(mapStateToProps)(NotificationsComponent),
    {
        initialViewer: graphql`
            fragment Notifications_initialViewer on Viewer {
                ...NotificationsIcon_viewer @include(if: $isClient) @defer
            }
        `,
        viewer: graphql`
            fragment Notifications_viewer on Viewer
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 5 }
                fetchNotifications: { type: "Boolean", defaultValue: false }
            ) {
                ...promoHelpers_trackGeoLocationPromos_viewer
                conversations: conversationsSolr(
                    filter: "showUnreadOnly"
                    first: $first
                    buyerId: $buyerId
                ) @include(if: $fetchNotifications) {
                    ...Conversations_conversations
                }
            }
        `,
        user: graphql`
            fragment Notifications_user on User {
                ... @include(if: $isClient) {
                    ...ShareActivityAlert_user @defer
                    ...ActivityFeed_user @defer
                    ...NotificationsIcon_user @defer
                }
            }
        `,
    },
    graphql`
        query NotificationsRefetchQuery(
            $first: Int
            $fetchNotifications: Boolean!
            $buyerId: String!
            $userId: String!
            $isGeoLocationPromoGroupSet: Boolean!
        ) {
            viewer {
                ...Notifications_viewer
                    @arguments(first: $first, fetchNotifications: $fetchNotifications)
            }
        }
    `
);

export default Notifications;
